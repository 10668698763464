import React from 'react';

import FormItem from '../../FormItem';
import FormLabel from '../../FormLabel';

import formStyles from '../../form.module.css';

const Images = ({ images, register }) => (
  <FormItem>
    <FormLabel>Virtual machine image</FormLabel>
    <select className={formStyles.input} name="image" ref={register()}>
      {images
        .sort((a, b) => (b.defaultSelected ? 1 : -1))
        .map((image) => (
          <option key={image.id} value={image.id}>
            {image.name}
          </option>
        ))}
    </select>
  </FormItem>
);

export default Images;
