import React, { memo, useState, useRef, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Link } from 'react-router-dom';
import { useSpring, a } from 'react-spring';

import styles from './Tree.module.css';

import { ReactComponent as Expand } from './icons/add.svg';
import { ReactComponent as NavigateNext } from './icons/navigate_next.svg';
import { ReactComponent as Shrink } from './icons/remove.svg';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => void (ref.current = value), [value]);
  return ref.current;
}

function useMeasure() {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current);
    }
    return () => ro.disconnect();
  }, [ro]);

  return [{ ref }, bounds];
}

// eslint-disable-next-line react/display-name
const Tree = memo(({ children, name, to, active, defaultOpen = false }) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();
  const hasChildren = !!children;

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const { height, opacity, transform } = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: 'translate3d(20px,0,0)'
    },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`
    }
  });
  const Icon = hasChildren && !isOpen ? Expand : active ? NavigateNext : Shrink;

  return (
    <div className={styles.frame} style={{ paddingTop: hasChildren ? '1.5em' : '1em' }}>
      <Icon
        className={hasChildren ? styles.toggle : styles.nonToggle}
        onClick={() => setOpen((x) => !x)}
      />
      <Link className={active ? styles.active : null} to={to}>
        {name}
      </Link>
      <a.div
        className={styles.content}
        style={{ opacity, height: isOpen && previous === isOpen ? 'auto' : height }}
      >
        <a.div style={{ transform }} {...bind}>
          {children}
        </a.div>
      </a.div>
    </div>
  );
});

export default Tree;
